.aboutSec {
  padding-top: 3rem;
  padding-bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  > div {
    background: radial-gradient(
      108.71% 228.83% at -22.65% 12.33%,
      rgba(0, 230, 118, 0.16) 0%,
      rgba(108, 99, 255, 0.08) 21.97%,
      rgba(0, 230, 118, 0.03) 98.44%
    );
    border: 1px solid #222d3a;
    border-radius: 16px;
  }
}
.about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: span 2;

  &__txtSec {
    padding-left: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 0;

    h3 {
      font-size: 36px;
      font-weight: 300;
      letter-spacing: -0.4px;
      line-height: 1.3;
      margin-bottom: 1rem;
      max-width: 600px;

      em {
        font-weight: 700;
      }
    }
    p {
      color: #a1a1a1;
      font-size: 17px;
      max-width: 470px;
      line-height: 1.5;
    }
  }

  img {
    max-width: 440px;
  }
}

.info {
  padding: 4rem;
  padding-bottom: 2rem;
  padding-right: 3rem;

  div {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1.5rem;

    h3 {
      font-size: 40px;
    }
  }

  p {
    color: #a1a1a1;
    font-size: 17px;
    // max-width: 470px;
    line-height: 1.5;
  }
}

@media screen and (max-width: 1100px) {
  .about {
    &__txtSec {
      padding-left: 2rem;
    }
  }
}

@media screen and (max-width: 900px) {
  .aboutSec {
    .about {
      flex-direction: column;
      align-items: flex-start;

      &__txtSec {
        padding-left: 1rem;
        padding-right: 1rem;

        h3 {
          font-size: 30px;
        }

        p {
          max-width: unset;
          font-size: 15px;
        }
      }

      img {
        margin: auto;
        width: 100%;
      }
    }
    .info {
      padding: 1.5rem 1rem;

      div {
        gap: 1rem;
        svg {
          width: 36px;
        }
        h3 {
          font-size: 30px;
        }
      }
      p {
        font-size: 15px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .aboutSec {
    grid-template-columns: 1fr;
    padding-bottom: 3rem;
    .about {
      grid-column: unset;
    }
  }
}
