.sec {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.ttl {
  color: #a5a5a5;
  letter-spacing: -0.3px;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 13px;
  margin-bottom: 3.5rem;

  em {
    color: #fff;
    font-weight: 600;
  }
}
.slider {
  display: block;
  background: transparent;
  margin: auto;
  overflow: hidden;
  position: relative;

  * {
    transition: 0.3s;
  }
}
.slideTrack {
  animation: scroll 20s linear infinite;
  display: flex;
  width: 1276px;
  align-items: center;
}

.slide {
  width: fit-content;
  padding-right: 2rem;

  img {
    margin: auto;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1276px);
  }
}
