.footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: 1140px;
  svg {
  }

  hr {
    background: radial-gradient(
      50% 58000.02% at 50% 50%,
      #282c40 0%,
      rgba(40, 44, 64, 0.2) 100%
    );
    height: 1px;
    border: none;
    margin-top: 2rem;
  }

  p {
    padding-top: 2rem;
    color: #8c929e;
    font-size: 13px;
    letter-spacing: -0.2px;
  }
}

@media screen and (max-width: 800px) {
  .footer {
    padding-bottom: 2rem;

    hr {
      margin-top: 1rem;
    }
    p {
      padding-top: 1rem;
    }
  }
}
