.servicesSec {
  padding-top: 6rem;
  padding-bottom: 4rem;
}
.txtSec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h2 {
    max-width: 600px;
    font-size: 44px;
    letter-spacing: -0.4px;
    line-height: 1.3;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    font-weight: 600;
  }

  > p {
    color: #a5a5a5;
    letter-spacing: -0.3px;
    max-width: 540px;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 3rem;
  }
}
.serviceList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}
.service {
  border: 1px solid #222d3a;
  background: #1a232d;
  padding: 1.2rem;
  padding-bottom: 1.5rem;
  border-radius: 16px;

  &__ttl {
    margin-bottom: 8px;
    font-size: 18px;
  }
  &__txt {
    color: #a1a1a1;
    margin-bottom: 4rem;
    font-size: 15px;
    line-height: 1.5;
    width: 90%;
  }

  svg {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .servicesSec {
    padding-top: 4rem;
  }
  .txtSec {
    h2 {
      font-size: 32px;
    }
  }
}
